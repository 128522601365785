<template>
	<v-checkbox
		color="secondary"
		:indeterminate="props.indeterminate"
		:model-value="props.modelValue"
		:disabled="props.disabled"
		density="compact"
		:hide-details="props.hideDetails"
		@update:model-value="updateValue"
	></v-checkbox>
</template>

<script lang="ts" setup>
interface CheckBoxProps {
	modelValue: boolean;
	disabled?: boolean;
	indeterminate?: boolean;
	hideDetails?: boolean;
}

const props = defineProps<CheckBoxProps>();

const emits = defineEmits(["update:modelValue"]);
const updateValue = (value: string) => emits("update:modelValue", value);
</script>

<style lang="scss" scoped>
:deep(.v-selection-control) {
	.v-icon {
		@apply opacity-100 h-[16px] w-[16px];
	}
	&:not(.v-selection-control--dirty) {
		.mdi-checkbox-blank-outline,
		.mdi-minus-box {
			@apply text-neutral-200;
		}
	}
}

:deep(.v-selection-control--focus-visible) {
	.v-selection-control__input::before {
		opacity: 0;
	}
	.v-selection-control__input {
		height: 20px;
		width: 20px;
		box-shadow: 0px 0px 3px 4px #0c7ce6;
		border-radius: 2px;
	}
}

:deep(.v-selection-control--disabled) {
	opacity: 1;

	&:not(.v-selection-control--dirty) {
		.mdi-checkbox-blank-outline,
		.mdi-minus-box {
			@apply text-neutral-100 bg-neutral-50;
		}
	}

	.mdi-checkbox-marked {
		box-shadow: 0 0 0 2px #e6e6e8;
		border-radius: 1px;
		@apply text-neutral-50 bg-neutral-200;
	}
	.mdi-minus-box {
		@apply text-neutral-50 bg-neutral-200;
	}
}
</style>
